var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-list-training" },
    [
      _c("h1", { staticClass: "title mb-4" }, [
        _vm._v(_vm._s(_vm.$t("lk.training.curators"))),
      ]),
      _c("list-training", { attrs: { to: "curator" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }