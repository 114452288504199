<template>
  <div class="page-list-training">
    <h1 class="title mb-4">{{ $t('lk.training.curators') }}</h1>
    <list-training :to="'curator'"></list-training>
  </div>
</template>

<script>
import ListTraining from "@/components/training/ListTraining";

export default {
  name: "PageListCurator",
  components: {
    ListTraining,
  },
};
</script>
